import React from 'react'
import styled from 'react-emotion'
import { Link } from 'gatsby'

const Container = styled('div')`
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  background: linear-gradient(#427ac2, #84d5b0);
  height: 100%;
  color: #f5f5f5;
  @media (max-width: 768px) {
   padding: 0 5%;
  }
`

const NotFoundPage = () => (
  <Container>
    <h1>Opps</h1>
    <p>
      You just hit a dead end... Click the button below to return to homepage
      and go through a list of articles.
    </p>

    <Link
      to="/"
      style={{
        color: 'white',
        background: '#333',
        padding: '5px',
        borderRadius: '2px',
        boxShadow: 'none'
      }}
    >
      Go Home
    </Link>
  </Container>
)

export default NotFoundPage
